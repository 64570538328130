#wrapper {
  display: flex;
}
/* #navbartopbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
} */
.navbar-search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 278px;
}
#navbar-nav {
  position: fixed;
  top: 0px;
  left: 0;

  width: 250px;
  height: 100%;

  z-index: 1000;
}

#content {
  margin-left: 250px;
  padding: 20px; /* Add some padding */
  flex-grow: 1; /* Allow content to grow */
}
#tabelcontent {
  width: 80vw;
  margin-left: 250px;
  padding: 20px; /* Add some padding */
  flex-grow: 1;
}
.home-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .navbar-search {
    margin-left: 0px;
  }
  #navbar-nav {
    width: 100px;
  }
  #content {
    margin-left: 0px;
    width: 100vw;
  }
  #tabelcontent {
    margin-left: 0px;
    width: 100vw;
  }
}

/* Default: Sidebar is hidden on mobile */
.sidenav {
  width: 250px;
  position: fixed;
  left: -250px;
  transition: all 0.3s;
  z-index: 999;
  top: 0px;
}

/* Style for WebKit-based browsers (Chrome, Safari) */
#sidenavscroll {
  overflow-y: auto;
  height: 100vh;
  overflow-x: hidden;
}

#sidenavscroll::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

#sidenavscroll::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
}

#sidenavscroll::-webkit-scrollbar-thumb:hover {
  background-color: transperent; /* Darker color on hover */
}

#sidenavscroll::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
  border-radius: 10px;
}

/* Firefox scrollbar styling */
#sidenavscroll {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: transparent transparent; /* Thumb color and track color */
}

/* Sidebar open for mobile */
.sidenav.open {
  left: 0;
  top: 0px; /* Show the sidebar when open */
}

/* On larger screens, ensure the sidebar is always visible */
@media (min-width: 768px) {
  .sidenav {
    left: 0;
  }
}
/* Add this to ensure the button is only visible on mobile */
#sidebarToggleTop {
  display: none;
}

@media (max-width: 768px) {
  #sidebarToggleTop {
    display: inline-block;
  }
}
.bill-template {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.bill-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

@media (max-width: 768px) {
  .bill-header {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: center; /* Align items to the left */
    margin-bottom: 15px;
  }

  /* Optional: You can also reduce padding or adjust font size if needed */
  .bill-header > * {
    margin-bottom: 10px; /* Add some spacing between elements */
  }
}

.bill-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.bill-table th,
.bill-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.bill-summary {
  text-align: right;
  margin-bottom: 20px;
}

.bill-summary p {
  font-size: 16px;
}

.bill-footer {
  text-align: center;
  margin-top: 20px;
}
.sidebar-brand-img img {
  height: 50px;
}
@media (max-width: 768px) {
  .sidebar-brand-img img {
    height: 25px;
  }
}

/* -------------------------------------AuthForm--------------------------------------------------------- */
.AuthForm-main-overlay-panel img {
  height: 200px;
}

.AuthForm-main {
  height: 100vh;
  /* background: #4e73df; */

  display: grid;
  place-content: center;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
:root {
  --linear-grad: linear-gradient(to right, #c03535, #4e73df);
  --grad-clr1: #4e73df;
  --grad-clr2: #4e73df;
}
.AuthForm-main-otp-container {
}
.AuthForm-main-AuthForm-container {
  position: relative;
  width: 850px;
  height: 500px;
  background-color: #fff;
  box-shadow: 25px 30px 55px #5557;
  border-radius: 13px;
  overflow: hidden;
}

.AuthForm-main-form-container {
  position: absolute;
  width: 60%;
  height: 100%;
  padding: 0px 40px;
  transition: all 0.6s ease-in-out;
}

.AuthForm-main-sign-up-container {
  opacity: 0;
  z-index: 1;
}

.AuthForm-main-sign-in-container {
  z-index: 2;
}
.AuthForm-main form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.AuthForm-main h1 {
  color: #333;
  font-size: 30px;
}

.AuthForm-main-social-container {
  margin: 20px 0px;
}

.AuthForm-main-social-container a {
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  height: 40px;
  width: 40px;
}

.AuthForm-main span {
  font-size: 12px;
}

.AuthForm-main-infield {
  position: relative;
  margin: 8px 0px;
  width: 100%;
}

.AuthForm-main input {
  width: 100%;
  padding: 12px 15px;
  background-color: #f3f3f3;
  border: none;
  outline: none;
}

.AuthForm-main label {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 0%;
  height: 2px;
  background: linear-gradient(to right, #c03535, #ff4b4b);
  transition: width 0.3s ease;
}

.AuthForm-main input:focus ~ .AuthForm-main label {
  width: 100%;
}

.AuthForm-main a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0px;
}

.AuthForm-main a.AuthForm-main-forgot {
  padding-bottom: 3px;
  border-bottom: 2px solid #eee;
}

.AuthForm-main-form-container button {
  border-radius: 20px;
  border: 1px solid #4e73df;
  background: #4e73df;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.AuthForm-main-form-container button {
  margin-top: 17px;
  transition: 80ms ease-in;
}

.AuthForm-main-form-container button:hover {
  background: #fff;
  color: #30478c;
}

.AuthForm-main-overlay-container {
  position: absolute;
  top: 0;
  left: 60%;
  width: 40%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 9;
}

.AuthForm-main-overlay-button {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translateX(-50%);
  width: 143.67px;
  height: 40px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 20px;
}

.AuthForm-main-overlay {
  position: relative;
  background: linear-gradient(to right, #30478c, #4e73df);
  color: #fff;
  left: -150%;
  height: 100%;
  width: 250%;
  transition: transform 0.6s ease-in-out;
}

.AuthForm-main-overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 40px;
  text-align: center;
  height: 100%;
  width: 340px;
  transition: 0.6s ease-in-out;
}

.AuthForm-main-overlay-left {
  right: 60%;
  transform: translateX(-12%);
}

.AuthForm-main-overlay-right {
  right: 0;
  transform: translateX(0%);
}

.AuthForm-main-overlay-panel h1 {
  color: #fff;
}

.AuthForm-main p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0px 0px 35px;
}

.AuthForm-main-overlay-panel button {
  border: none;
  background-color: transparent;
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-container {
  transform: translateX(-150%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay {
  transform: translateX(50%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-left {
  transform: translateX(25%);
}

.AuthForm-main-right-panel-active .AuthForm-main-overlay-right {
  transform: translateX(35%);
}

.AuthForm-main-right-panel-active .AuthForm-main-sign-in-container {
  transform: translateX(20%);
  opacity: 0;
}

.AuthForm-main-right-panel-active .AuthForm-main-sign-up-container {
  transform: translateX(66.7%);
  opacity: 1;
  z-index: 5;
  animation: AuthFormshow 0.6s;
}
.AuthForm-main-right-panel-active .AuthForm-main-otp-container {
  transform: translateX(66.7%);
  opacity: 1;
  z-index: 5;
  animation: AuthFormshow 0.6s;
}
.AuthForm-main-sign-in-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes AuthFormshow {
  0%,
  50% {
    opacity: 0;
    z-index: 1;
  }
  50.1%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.AuthForm-main-btnScaled {
  animation: AuthFormscaleBtn 0.6s;
}

@keyframes AuthFormscaleBtn {
  0% {
    width: 143.67px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 143.67px;
  }
}

.AuthForm-main-square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

.AuthForm-main-big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #c03535, #ff4b4b);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.AuthForm-main-inner-circle {
  position: absolute;
  width: 72%;
  height: 72%;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.AuthForm-main-infield span {
  position: absolute;
  top: 10px;
  right: 20px;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .AuthForm-main p {
    margin: 0px 0px 35px;
  }
  .AuthForm-main-overlay-panel img {
    height: 120px;
  }
  .AuthForm-main {
    height: 80vh;
    background: #f6f5f7;
    display: grid;
    place-content: center;
    font-family: "Poppins", sans-serif;
    overflow: hidden;
  }
  .AuthForm-main-AuthForm-container {
    position: relative;
    width: 350px;
    height: 500px;
    background-color: #fff;
    box-shadow: 25px 30px 55px #5557;
    border-radius: 13px;
    overflow: hidden;
  }
  .AuthForm-main-form-container {
    position: absolute;
    width: -1%;
    height: 100%;
    padding: 0px 0px;
    transition: all 0.6s ease-in-out;
  }
  .AuthForm-main-overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px;
    text-align: center;
    height: 100%;
    width: 130px;
    transition: 0.6s ease-in-out;
  }
  .AuthForm-main form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
  }
  .AuthForm-main-overlay-button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 340px;
    transform: translateX(-50%);
    width: 85px;
    height: 40px;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 20px;
  }
  .AuthForm-main h1 {
    color: #fff;
    font-size: 22px;
  }
}
.excelbtn {
  height: 38px;
  width: 130px;
}
.create-bill .billcreate-left {
  width: 100px;
}
.create-bill .billcreate-leftdot {
  width: 10px;
}
/* Hide everything except #print-area during print */
@media print {
  body * {
    visibility: hidden;
  }

  #print-area,
  #print-area * {
    visibility: visible;
  }

  #print-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
.custom-bill-summary-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9; /* Light background color */
}

.custom-bill-summary-table th,
.custom-bill-summary-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}
.custom-bill-summary-table th,
.custom-bill-summary-table td input {
  text-align: left;
  background: none;
  border: none;
  width: 70px;
}

.custom-bill-summary-table th {
  background-color: #4caf50; /* Green background for the header */
  color: white;
  font-size: 18px;
}

.custom-bill-summary-table td {
  background-color: #fff; /* White background for table data */
}

.custom-bill-summary-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Zebra stripe effect for even rows */
}

.custom-bill-summary-table tr:hover {
  background-color: #ddd; /* Slight hover effect for rows */
}

.table-title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #4caf50;
  background-color: #f1f1f1;
}

.label {
  font-weight: 600;
}

.total-label {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.total-value {
  text-align: center;
  color: #4caf50;
  font-weight: bold;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px; /* Adjust size as needed */
}

.quantity-button:hover {
  background-color: #ddd;
}

.quantity-input {
  width: 60px; /* Adjust the input size */
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  margin: 0 10px;
  padding: 5px;
}

/* Make tables responsive */
.bill-table,
.custom-bill-summary-table {
  width: 100%;
  margin-bottom: 20px;
}

.table-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #f8f9fa;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.quantity-input {
  width: 60px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  margin: 0 10px;
  padding: 5px;
}

.label {
  font-weight: bold;
}

.total-label {
  font-weight: bold;
}

.total-value {
  font-weight: bold;
  color: #007bff;
}

@media (max-width: 768px) {
  /* On smaller screens, allow horizontal scroll */
  .bill-table,
  .custom-bill-summary-table {
    overflow-x: auto;
    display: block;
  }
  .custom-bill-summary-table th,
  .custom-bill-summary-table td input {
    width: 30px;
  }

  .quantity-control {
    flex-direction: column;
    align-items: flex-start;
  }

  .quantity-button {
    width: 40px;
    padding: 5px;
  }

  .quantity-input {
    width: 50px;
    margin: 5px 0;
  }

  .table-title {
    font-size: 1.1rem;
  }

  /* Style for smaller screens */
  td {
    font-size: 14px;
  }

  .total-label,
  .total-value {
    font-size: 16px;
  }
}
.quotationbillimg {
  height: 100px;
}
.custom-select-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.custom-select-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

.custom-select {
  width: 150%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  outline: none;
  transition: all 0.3s ease;
}

.custom-select:focus {
  border-color: #4caf50;
  background-color: #ffffff;
}
.billquot {
  width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.billquot h3 {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.billquot .create-bill {
  margin-bottom: 20px;
}

.billquot .create-bill p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
  font-weight: bold;
}

.billquot .d-flex {
  margin-top: 20px;
}

.billquot button {
  border-radius: 5px;
  padding: 10px 20px;
}

.billquot button:focus {
  outline: none;
  box-shadow: 0 0 4px #007bff;
}
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #3498db, #2ecc71);
}

.auth-box {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.auth-logo {
  width: 200px;
  margin-bottom: 20px;
}

.auth-box h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.auth-box p {
  font-size: 14px;
  color: #777;
  margin-bottom: 20px;
}

.auth-input {
  position: relative;
  margin-bottom: 20px;
}

.auth-input input {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: 0.3s;
}

.auth-input input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #777;
}

.auth-error {
  color: #d9534f;
  font-size: 14px;
  margin-bottom: 15px;
}

.auth-button {
  background: #007bff;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background 0.3s;
}

.auth-button:hover {
  background: #0056b3;
}

.auth-button:disabled {
  background: #c0c0c0;
  cursor: not-allowed;
}
